.announcement {
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    width: 100%;
    height: 40px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: black;
    font-family: var(--font-poppins);
}
