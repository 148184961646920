.footer {
  background-color: #fafafa;
  font-family: var(--font-poppins);
  margin-top: 25px;

  .footer_info {
    padding: 2rem;

    .about_us {
      h5 {
        font-weight: 500;
        text-transform: uppercase;
      }
      div {
        margin-top: 25px;
        p {
          color: var(--color-gray);
        }
      }
    }
    .information {
      h5 {
        font-weight: 500;
        text-transform: uppercase;
      }
      div {
        margin-top: 25px;
        li {
          color: var(--color-gray);
          list-style: none;
          margin: 5px 0;
          font-size: 16px;
        }
      }
    }
    .newsletter {
      h5 {
        font-weight: 500;
        text-transform: uppercase;
      }
      div {
        margin-top: 25px;
        p {
          color: var(--color-gray);
          font-size: 16px;
        }
        input {
          width: 80%;
          height: 35px;
          border: 1px solid black;
          outline: none;
          background-color: #fff;
          padding: 0.5rem 1rem;
        }
        button {
          border: none;
          height: 35px;
          padding: 0 0.5rem;
          background-color: black;
          color: white;
        }
      }
    }
  }
  .copyright {
    height: 50px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #fff;
    }
  }
}
