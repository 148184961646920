.profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  background-color: #fff;
  font-family: var(--font-poppins);
  .row {
    // display: flex !important;
    // flex-wrap: wrap;

    .profile_container {
      background-color: #fff;
      box-shadow: 0 4px 8px 5px rgba(232, 226, 226, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-radius: 10px;

      div {
        a {
          text-decoration: none;
          background-color: #f2effe;
          padding: 5px 15px;
          border: none;
          border-radius: 20px;
          color: #8067f8;
          transition: all 0.5s ease-in-out;

          &:hover {
            background-color: #ddd9e9;
          }
        }
      }

      .image {
        text-align: center;
        img {
          height: 250px;
          width: 250px;
          border-radius: 50%;
        }
      }
    }
  }
}
