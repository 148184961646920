.product_details {
  width: 100%;
  min-height: 88.5vh;
  padding: 0;
  margin: 0;
  overflow: hidden;

  .preview_image {
    height: 400px;
    width: 400px;
    border-radius: 20px;
    img {
      width: 350px;
      border-radius: 20px;
    }
  }

  .image_thumbline {
    display: flex;
    align-items: center;
    width: 450px;

    div {
      img {
        height: 100px;
        width: 135px;
        margin: 5px;
      }
    }
  }

  .Product_info {
    p {
      color: var(--color-gray);
      font-size: 16px;
      margin-top: 5px;
    }
  }
}
