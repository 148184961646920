.new_product {
    width: 100%;
    min-height: 88.5vh;
    padding: 0;
    margin: 0;
    overflow: hidden;

    .product_input {
        display: flex;
        align-items: center;
        justify-content: center;

        .form {
            background-color: white;
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            // backdrop-filter: blur(4px);
            // -webkit-backdrop-filter: blur(4px);
            // border: 1px solid rgba(255, 255, 255, 0.18);
            padding: 2rem;
            border-radius: 20px;
            min-width: 50%;
            margin-top: 10px;

            .from_group {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-top: 15px;

                label {
                    color: black;
                    font-weight: 500;
                    letter-spacing: 1px;
                }

                input {
                    height: 40px;
                    border-radius: 20px;
                    border: 2px solid #fff;
                    outline: none;
                    padding: 0.5rem 1rem;
                    background-color: rgb(216, 214, 214);
                    font-size: 16px;
                }

                textarea {
                    height: 100px;
                    border-radius: 20px;
                    border: 2px solid #fff;
                    outline: none;
                    padding: 0.5rem 1rem;
                    background-color: rgb(216, 214, 214);
                    font-size: 16px;
                }

                select {
                    height: 40px;
                    border-radius: 20px;
                    border: 2px solid #fff;
                    outline: none;
                    padding: 0.5rem 1rem;
                    background-color: rgb(216, 214, 214);
                    font-size: 16px;
                }

                button {
                    height: 35px;
                    border-radius: 20px;
                    border: none;
                    width: 80%;
                    margin: 1rem auto;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 500;
                    transition: all 0.5s ease-in-out;

                    &:hover {
                        width: 90%;
                    }
                }
            }
        }
    }
}
