.product_details {
  margin: 10px;
  margin-top: 75px;
  font-family: var(--font-poppins);
  .leftSide {
    border-radius: 20px;
    max-width: 440px;
    margin: 0px auto;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-self: center;

    .preview_image {
      background-color: white;

      display: flex;
      justify-content: center;
      width: 500px;
      border-radius: 20px;

      @media screen and (max-width: 900px) {
        height: 200px;
        width: 350px;
      }
      img {
        max-width: 100%;
        height: auto;
        border-radius: 20px;

        @media screen and (max-width: 900px) {
          max-width: 100%;
        }
      }
    }

    .products_container {
      display: flex;
      flex-direction: row;
      width: 450px;
      position: relative;

      @media screen and (max-width: 900px) {
        width: 350px;
      }

      .products_container_branch {
        margin-top: 5px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        width: max-content;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .item {
          display: flex;
          margin: 1rem;
          flex-direction: column;
          align-items: center;
          width: 135px;
          border-radius: 10px;
          img {
            height: 120px;
            width: auto;
            border-radius: 10px;
          }
        }
      }

      .app__gallery_images_arrows {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0 1rem;
        position: absolute;
        bottom: 0%;
      }

      .gallery__arrow_icon {
        color: var(--color-black);
        font-size: 2rem;
        cursor: pointer;
        background: var(--color-golden);
        border-radius: 5px;

        &:hover {
          color: var(--color-white);
        }
      }
    }
  }

  .Product_info {
    padding-left: 20px;
    p {
      color: var(--color-gray);
      font-size: 16px;
      margin-top: 5px;
    }

    .stock_counter {
      display: flex;
      gap: 10px;

      span {
        padding: 5px 10px;
        border-radius: 50%;
        border: 2px solid var(--color-gray);
        &:nth-child(1) {
          &:hover {
            background-color: rgb(182, 9, 9);
            border: 2px solid maroon;
            color: white;
          }
        }
        &:nth-child(3) {
          &:hover {
            background-color: rgb(12, 146, 12);
            border: 2px solid green;
            color: white;
          }
        }
      }

      input {
        width: 10%;
        padding: 5px 10px;
        border-radius: 20px;
        outline: 2px solid green;
        border: none;
        text-align: center;
      }
    }
    .button {
      button {
        padding: 0.5rem 1rem;
        border-radius: 25px;
        margin-top: 10px;

        &:nth-child(1) {
          border: 2px solid black;
          background-color: black;
          color: white;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: rgb(24, 21, 21);
            border: 2px solid rgb(24, 21, 21);
          }
        }
        &:nth-child(2) {
          margin-left: 10px;
          border: 2px solid black;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: rgb(24, 21, 21);
            border: 2px solid rgb(24, 21, 21);
            color: white;
          }
        }
      }
    }
    .review {
      margin-top: 20px;
      button {
        padding: 0.5rem 1rem;
        border-radius: 20px;
        border: 1px solid goldenrod;
      }
      .review_card {
        position: absolute;
        top: 30%;
        right: 30%;
        background: #fff;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        padding: 2rem;
        border-radius: 20px;
        width: 400px;
        z-index: 999;

        div {
          .icon {
            background-color: unset;
            border: none;
            color: red;
            transition: all 0.5s ease;

            &:hover {
              transform: scale(1.2, 1.2);
            }
          }
          button {
            border: none;
            background-color: goldenrod;
            padding: 0.5rem 1rem;
            border-radius: 20px;
            font-weight: 500;
            margin-top: 15px;
            color: #fff;

            &:hover {
              background-color: rgb(204, 179, 34);
            }
          }
        }
      }
    }
  }
}
