.navbar {
    height: 60px;
    background: rgba(230, 228, 228, 0.25);
    // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    // backdrop-filter: blur(4px);
    // -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 0 1rem;

    display: flex;
    align-items: center;
    font-family: var(--font-poppins);
    width: 100%;
}
