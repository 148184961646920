.user_details {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: #fff;
    font-family: var(--font-poppins);
    position: relative;

    .user_information {
        background-color: #fff;
        box-shadow: 0 4px 8px 5px rgba(232, 226, 226, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;

        div {
            button {
                background-color: #f2effe;
                padding: 5px 15px;
                border: none;
                border-radius: 20px;
                color: #8067f8;
                transition: all 0.5s ease-in-out;

                &:hover {
                    background-color: #ddd9e9;
                }
            }
        }
    }

    .modal {
        position: absolute;
        top: 20%;
        right: 30%;
        background: #fff;
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border: 1px solid rgba(255, 255, 255, 0.18);
        padding: 2rem;
        border-radius: 20px;
        width: 400px;
        z-index: 999;

        .from_group {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 15px;

            label {
                color: white;
                font-weight: 500;
                letter-spacing: 1px;
            }

            input {
                height: 40px;
                border-radius: 20px;
                border: 2px solid #fff;
                outline: none;
                padding: 0.5rem 1rem;
                background-color: rgb(216, 214, 214);
                font-size: 16px;
            }
            select {
                height: 40px;
                border-radius: 20px;
                border: 2px solid #fff;
                outline: none;
                padding: 0.5rem 1rem;
                background-color: rgb(216, 214, 214);
                font-size: 16px;
            }

            button {
                height: 35px;
                border-radius: 20px;
                border: none;
                width: 80%;
                margin: 1rem auto;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 500;
                transition: all 0.5s ease-in-out;

                &:hover {
                    width: 90%;
                }
            }
        }
    }
}
