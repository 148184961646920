@import "../../colors.scss";
.container {
  display: flex;
  justify-content: center;

  .carousel-container {
    background-color: $Color1;
    width: 100%;
    height: 350px;
    margin: 0px 20px;
    padding: 0 auto;
    display: flex;
    justify-content: center;

    .carousel-image {
      position: relative;
      border-radius: 50%;
      /* padding: 20px; */
      width: 800px;
      display: flex;
      justify-content: center;

      img {
        /* width: 100%; */
        height: 100%;
        padding: 30px;
        margin-bottom: 20px;
        border-radius: 50%;
      }

      .carousel-caption {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
        color: #fff;
        background-color: $Color1;
        padding: 10px;

        h2 {
          margin: 0;
          font-size: 20px;
        }

        p {
          margin: 0;
          font-size: 22px;
        }
      }
    }
  }

  .arrow-button {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border: none;
    color: #000;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &.left {
      left: 0%;
      background-color: white;
    }

    &.right {
      left: 0%;
      background-color: white;
    }

    &:hover {
      background-color: #ccc;
    }
  }
}
