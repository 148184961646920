.review {
    font-family: var(--font-poppins);
    .form_group {
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            height: 35px;
            margin-top: 10px;
            border-radius: 20px;
            outline: none;
            padding: 0.5rem 1rem;
            width: 100%;
        }

        button {
            border: none;
            margin-top: 10px;
            padding: 5px 15px;
            border-radius: 20px;
            background-color: burlywood;
            color: black;
        }
    }
}
