@import "../../colors";

.nav_container {
  .navbar {
    background-color: $Color1;
    z-index: 1000;
    font-family: var(--font-poppins);
    width: 100%;
    height: 80px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-navbar {
      display: flex;
      .logo a {
        text-decoration: none !important;
        color: white;
      }
    }

    .search {
      position: relative;
      input {
        border-radius: 25px;
        height: 40px;
        outline: none;
        padding: 0.5rem 1rem;
        background-color: #fff;
        margin-left: 60px;
      }
      button {
        position: absolute;
        right: 10px;
        background-color: #fff;
        border: none;
      }

      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    .nav_links {
      ul {
        list-style: none;

        .cart {
          position: relative;
          span {
            position: absolute;
            background-color: white;
            color: black;
            padding: 0px 8px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -50%;
            right: -50%;
            font-weight: 500;
            font-size: 16px;
          }
        }

        li {
          margin: 0.4rem 0.5rem 0rem 0.5rem;
          color: white;
          a {
            text-decoration: none;
            color: white;
            cursor: pointer;
          }

          button {
            background-color: unset;
            border: 2px solid white;
            border-radius: 20px;
            padding: 0.2rem 1rem;
            color: white;
          }
        }
        .dropdown {
          position: absolute;
          padding: 1rem;
          top: 82px;
          right: 150px;
          min-width: 200px;
          z-index: 999;
          border-radius: 10px;

          display: flex;
          flex-direction: column;

          background-color: black;

          a {
            text-decoration: none;
            font-weight: 500;
            color: #fff;
            padding: 0.5rem 2rem;
            border-radius: 5px;
            transition: all 0.3s;

            &:hover {
              background-color: rgb(172, 235, 214);
            }
          }
        }
      }
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }
  .links {
    background-color: #fff;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: black !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
    a {
      color: #fff;

      &:hover {
        color: #fb774b;
      }
    }
  }
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-golden);

    svg {
      width: 70%;
      height: 70%;
      color: var(--color-white);
    }

    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;

      padding: 1rem;

      width: 80%;
      height: 100vh;

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;

      // background: url("../../assets/bgWhite.png");
      background-color: var(--color-white);
      background-size: cover;
      background-repeat: repeat;

      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        li {
          margin: 0rem 1rem;
          a {
            color: var(--gray-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;

            transition: all 0.3s ease-in-out;

            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }

      @media screen and (min-width: 900px) {
        display: none;
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }
}
